<template>
	<div>
		<layout-default-new>
			<sub-navbar page="Accounting" />
			<div v-if="loading">Loading....</div>
			<div v-else-if="!loading">
				<b-button class="is-success margin-bottom-20" @click="refreshData">
					Refresh Table
				</b-button>
			</div>
			<ve-table
				:rows="rowsList"
				:columns="columnsList"
				:onCellClick="onCellClick"
				:onRowDoubleClick="onRowDoubleClick"
			>
			</ve-table>
		</layout-default-new>
	</div>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import GetBookkeepingReportList from '@/_srcv2/pages/accounting/reports/graphql/BookkeepingReportListQuery.graphql'
import { onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import storeAccounting from '@/_srcv2/pages/accounting/accounting-store/storeAccounting'
import Store from '@/store'
import Router from '@/router'

// import Router from '@/router'
export default {
	name: 'BookkeepingReportList',
	components: {
		SubNavbar,
	},
	setup() {
		const rowsList = ref([])
		const columnsList = reactive([
			{
				label: 'Select',
				field: 'select',
				type: 'html',
				width: '50px',
				sortable: false,
			},
			{
				label: 'Report Date',
				field: 'report_date',
				type: 'date',
				dateInputFormat: 'yyyy-MM-dd',
				dateOutputFormat: 'yyyy-MM-dd',
			},
			{
				label: 'Report Number',
				field: 'report_number',
				type: 'number',
			},
			{
				label: 'Report Type',
				field: 'report_type',
			},
		])
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch } = useQuery(
			GetBookkeepingReportList,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refreshData = () => {
			refetch().then((result) => {
				console.log(' from refetch', result.data.collect_payment_report_headers)
				rowsList.value = result.data.collect_payment_report_headers
			})
		}
		onMounted(() => {
			refreshData()
		})
		// ------------------------------------------------------------------------------------
		const { reportHeaders } = storeAccounting()
		const getReportType = (payload) => {
			switch (payload) {
				case 'collectedPaymentReport':
					return 'Inbetalningsjournal nr. '
				case 'payoutReport':
					return 'Utbetalningsjournal nr. '
				case 'setOffReport':
					return 'Övriga transaktionsjournal nr. '
				default:
					return 'Övriga transaktionsjournal nr. '
			}
		}
		console.log(
			'-------collectedPaymentReport',
			getReportType('collectedPaymentReport'),
		)
		const setVariables = (data) => {
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					console.log('************data.reportType', data.report_type)
					reportHeaders.ourCompany = data.our_company
					reportHeaders.reportNumber = data.report_number
					reportHeaders.reportType = getReportType(data.report_type)
					reportHeaders.reportDate = data.report_date
					console.log('-----reportHeaders.reportType', reportHeaders.reportType)
					resolve(reportHeaders)
				} else {
					const reason = new Error(
						'Report headers could not be fetched from database',
					)
					reject(reason)
				}
			})
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('****----params.row', params.row)
				setVariables(params.row)
					.then(() => Router.push({ name: 'GetCollectedPaymentsReport' }))
					.then(() => console.log(''))
			}
		}
		const onRowDoubleClick = (params) => {
			setVariables(params.row)
				.then(() => Router.push({ name: 'GetCollectedPaymentsReport' }))
				.then(() => console.log(''))
		}
		return {
			rowsList,
			columnsList,
			loading,
			refreshData,
			onCellClick,
			onRowDoubleClick,
		}
	},
}
</script>

<style scoped></style>
